<template>
  <div>
    <TopNav></TopNav>
    <div class="first">
<!--      <div class="fy_center_box">-->
<!--        <div class="center_box">-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="second">
      <!-- bounceInLeft -->
      <div class="second-box">
        <div class="second-box1 animated fadeInLeft">
          <div class="second-box1-1">经方云中医智能云服务平台</div>
          <div class="second-box1-2">经方云中医智能云服务平台是经方云科技创新研发的基于中医大数据和人工智能技术、中医辨证
            分析为核心的区域一体化辅助诊断平台。平台以中医院为中心，县、乡、村一体化部署，融合智能诊断、
            常见病诊断、远程会诊、远程教育、知识库和共享药房六大核心引擎，以“互联网+中医”技术实现中医优质
            资源下沉，快速提升基层中医诊疗水平，让老百姓足不出村即可享受到高质量的中医药服务、中药配送
            到家服务、健康管理服务。</div>
        </div>
        <div class="second-box2 animated fadeInRight">

          <img src="../static/img/scheme/scheme-1.png" height="581" width="745"/>
<!--          <img src="../static/img/scheme/scheme-1.png" height="478" width="637"/>-->
        </div>
      </div>
      <div class="second-box bottom-box">
        <div class="second-box3 animated fadeInLeft">
          <img src="../static/img/scheme/scheme-2.png" height="478" width="637"/>
        </div>
        <div class="second-box1 animated fadeInRight">
          <div class="second-box1-1">智慧共享药房</div>
          <div class="second-box1-2">
            “人工智能+中医”这一创新服务模式，打破了时间、空间、地域上的限制，使患者足不出村就可以享受到高质量中医医疗服务，不仅帮助患者避免了往返的奔波劳苦，降低了患者的就医成本，还为患者赢得了宝贵的就诊时间。既帮助基层医生确诊疑难杂症，也使基层医生随时随地接受远程医学教育培训。
          </div>
          <div  class="second-box1-2">
            实现区域（市、县、乡、村）一体化部署，支持区域中医医联体建设，快速提升基层中医的诊疗服务能力，有效促进中医的传承和发展，助力国家分级诊疗，实现优质医疗资源下沉，让”简、验、便、廉“的中医诊疗方法惠及广大人民群众，从根本上缓解看病难、费用高、体验差的问题。
          </div>
        </div>
      </div>
<!--      <div-->
<!--        :class="['table_box animated',isChange?'fadeInLeft':'fadeOutRight']"-->
<!--        v-for="(p,index) in personList"-->
<!--        :key="index"-->
<!--        v-show="currentIndex==index&&isChange"-->
<!--      >-->
<!--        <div class="fy_center_box">-->
<!--          <div class="person">-->
<!--            <img src="@/static/image/applications/person.png" v-show="index==0 || index==2" />-->
<!--            <img src="@/static/image/applications/person1.png" class="personImg" v-show="index==1" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="table_right_box">-->
<!--        <div-->
<!--          :class="['table_right animated',currentIndex==index?'fadeInRight':'fadeInLeft']"-->
<!--          v-for="(p,index) in personList"-->
<!--          :key="index"-->
<!--          v-show="currentIndex==index&&isChange"-->
<!--        >-->
<!--          <p class="des">{{p.des}}</p>-->
<!--          <img :src="p.imgUrl" class="row_des" />-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="node-img1">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="three">
      <div class="fy_center_box">
        <img src="../static/img/scheme/scheme-5.png" height="76" width="209"/>
        <img src="../static/img/scheme/scheme-6.png" height="514" width="1153"/>
      </div>
    </div>
<!--    <div class="fourth">-->
<!--      <div class="fy_center_box">-->
<!--        <p class="title">-->
<!--          智-->
<!--          <span>·</span>更全面-->
<!--          <span style="padding:0 10px;"></span> 知-->
<!--          <span>·</span>全覆盖-->
<!--        </p>-->
<!--        <img src="@/static/image/about/under_title.png" style="width:52px;height:2px;" />-->
<!--        <div class="center">-->
<!--          <img src="@/static/image/applications/mac.png" class="mac_img" />-->
<!--          <div class="p_box">-->
<!--            <p>PC端：智能预问诊和智能辅助建议系统，打造“轻”问诊智能解决方案</p>-->
<!--            <p>APP：智能匹配手机，ipad等移动客户端（Android，IOS皆适用），随时随地把健康握在手里。</p>-->
<!--            <p>微信小程序，公众号：发现新奇有趣的健康知识，了解产品内容，您身边的健康小助手。</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <Footer></Footer>
  </div>
</template>
<script>
import TopNav from "@/components/Nav/TopNav.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  name: "Applications",
  data() {
    return {
      lists: [1, 2, 3],
      personList: [
        {
          name: "患者服务机器人",
          des:
            "患者服务机器人可以有效的优化就诊流程，智能分诊，提高医生服务效率。可以帮助患者合理分诊、排队，利用患者候诊时间可以帮助患者建立健康档案、智能四诊采集等。支持自然语言、图像、动作等多种交互方式，患者不仅可以与其语音互动，还可以按照导航在电子屏上直接互动完成问诊、望诊等体征数据采集，让患者轻松享受到智慧医疗的全新体验。",
          imgUrl: require("@/static/image/applications/hz.png")
        },
        {
          name: "辅助诊断机器人",
          des:
            "辅助诊断机器人通过人脸识别技术自动识别患者并自动导航采集舌象、面象、望诊、问诊等四诊信息（一部分四诊信息由中医师根据导航采集完成），并模拟名老中医的诊疗思路、经验智慧和辩证分析过程快速的开出高质量的、经过验证的、疗效可靠的处方，复杂的病例机器人还可以根据病情匹配名医进行远程可视化诊疗指导。使更多普通医师能够进一步融入到医圣张仲景的诊治过程中，帮助普通医生提升诊疗能力。",
          imgUrl: require("@/static/image/applications/zm.png")
        },
        {
          name: "家庭健康管理机器人",
          des:
            "家庭健康管理机器人为全家成员提供全面的、个性化的健康管理。通过强大的“望闻问切”能力，依据“经方云中医大脑”和“经方云临床中医大数据系统”，实时对个体体质做出健康预警、未病预测等，并能在初期阶段做出科学的干预措施、膳食营养合同调整、合理运动指导建议等一体化方案，尤其在预防保健、健康养生、慢病防治等方面效果独特。",
          imgUrl: require("@/static/image/applications/jt.png")
        }
      ],
      swiperOption: {},
      mySwiper: {},
      mySwiper1: {},
      currentIndex: 0,
      isChange: true
    };
  },
  mounted() {},
  methods: {
    changeIndex(index) {
      // if (this.isChange) {
      // return;
      // }
      this.currentIndex = index;
    }
  },
  watch: {
    currentIndex(val) {
      this.isChange = true;
    }
  },
  components: {
    TopNav,
    Footer
  }
};
</script>
<style lang="scss" scoped>
.first {
  width: 100%;
  height: 360px;
  margin-top: 80px;
  background: url("./../static/img/scheme/scheme-bg1.jpg") no-repeat center;
  //background-size: cover;
}
.second {
  position: relative;
  padding-top: 110px;
  padding-bottom: 80px;
  width: 1200px;
  margin: 0 auto;
  height: 1350px;
  text-align: center;
  //overflow: hidden;
  .second-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .second-box1{
      width: 416px;
      .second-box1-1{
        color: #536981;
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: left;
      }
      .second-box1-2{
        color: #8091A5;
        font-size: 16px;
        text-indent: 40px;
        text-align: left;
        line-height: 30px;

      }
    }
    .second-box2{
      width: 745px;
    }
    .second-box3{
      width: 400px;
    }
  }
  .bottom-box{
    margin-top: 70px;
  }
  .second_top {
    position: relative;
    height: 60px;
    margin-top: 60px;
    z-index: 9;
    .row {
      position: absolute;
      right: 10px;
      display: flex;
      justify-content: space-between;
      width: 640px;
      height: 100%;
      overflow: hidden;
      .top_btn {
        display: inline-block;
        width: 190px;
        height: 100%;
        line-height: 60px;
        font-size: 18px;
        text-align: center;
        color: #3565d7;
        background-image: url("./../static/image/applications/top_btn1.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        &:hover {
          cursor: pointer;
        }
      }
      .top_btn.active {
        background-image: url("./../static/image/applications/top_btn.png");
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .title {
    font-size: 30px;
    color: #191e4a;
    letter-spacing: 4px;
  }

  .table_box {
    position: absolute;
    top: 230px;
    left: 0;
    width: 100%;
    overflow: hidden;
    .person {
      position: relative;
      top: 20px;
      display: block;
      float: left;
      width: 500px;
      height: 550px;
      overflow: hidden;
      margin-top: 0px;
      // padding-left: 200px;
      img {
        width: 500px;
        height: 550px;
      }
    }
  }
  .table_right_box {
    width: 1200px;
    white-space: nowrap;
    margin: 0 auto;
  }

  .table_right {
    display: inline-block;
    width: 1200px;
    height: 556px;
    // margin: 0 auto;
    padding-left: 180px;
    padding-top: 30px;
    white-space: normal;
    .des {
      display: block;
      width: 640px;
      color: #5b607f;
      letter-spacing: 1.2px;
      text-align: left;
      line-height: 36px;
      float: right;
    }
    .row_des {
      display: block;
      width: 540px;
      height: 260px;
      margin-top: 30px;
      margin-right: 50px;
      float: right;
    }
  }
}
.three {
  width: 100%;
  min-width: 1200px;
  height: 664px;
  background: url("./../static/img/scheme/scheme-4.png") no-repeat center;
  //background-size: cover;
  padding-top: 80px;
  .title {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 36px;
    margin-bottom: 50px;
  }
  .des {
    width: 1000px;
    text-indent: 2em;
    color: #ebfcff;
    line-height: 30px;
    margin: 0 auto;
  }
  .center_img {
    display: block;
    width: 454px;
    height: 276px;
    margin: 0 auto;
    margin-top: 52px;
  }
}
.fourth {
  .fy_center_box {
    text-align: center;
    padding-top: 78px;
    padding-bottom: 44px;
    .title {
      font-size: 30px;
      color: #191e4a;
      span {
        font-size: 36px;
        font-weight: bold;
        line-height: 30px;
      }
    }
    .center {
      display: block;
      width: 100%;
      height: 428px;
      margin-top: 50px;
      .mac_img {
        display: block;
        width: 484px;
        height: 428px;
        float: left;
      }
      .p_box {
        width: 520px;
        display: block;
        margin-left: 190px;
        margin-top: 30px;
        float: left;
        text-align: left;
        p {
          width: 100%;
          line-height: 40px;
          color: #5b607f;
        }
      }
    }
  }
}
.node-img1{
  width: 100%;
  height: 30px;
  text-align: center;
  position: relative;
  top: 10px;

}
</style>
